import { TABLES } from "../utils/supabase"

export const getPortfolioReconciliated = async (supabase, date) => {
  const { data, error } = await supabase.from(TABLES.BATIMENTO_CARTEIRAS_FIDC).select("*").eq("data", date)
  if (error) throw error
  return data
}

export const getReconciliationDatesResume = async (supabase) => {
  const { data, error } = await supabase.from(TABLES.VIEW_RECONCILIATION_DATES_RESUME).select("*")
  if (error) throw error
  return data
}

export const insertPortfolioReconciliation = async (supabase, portfolio, extract) => {
  const { error } = await supabase.from(TABLES.BATIMENTO_CARTEIRAS_FIDC).insert(portfolio)
  if (error) throw error

  const { error: errorExtract } = await supabase.from(TABLES.BATIMENTO_EXTRATOS_FIDC).insert(extract)
  if (errorExtract) throw errorExtract
} 