import { Menu } from "antd";
import { colors } from "../style/colors";

export const HeaderMenu = ({ setMenuKey, menuItems }) => {
  return (
    <Menu
      mode="horizontal"
      defaultSelectedKeys={["1"]}
      items={menuItems}
      style={{ flex: 1, minWidth: 0, backgroundColor: colors.menuBackgroundColor, borderBottom: 'none' }}
      onClick={(e) => setMenuKey(e.key)}
    />
  );
};
