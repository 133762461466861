import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import { PortfolioReconciliationContext } from "../context/PortfolioReconciliationContext"
import { getFidcShortName } from "../services/FidcService"
import { getReconciliationDatesResume, insertPortfolioReconciliation } from "../services/PortfolioReconciliationService"
import { saveFilesInDataLake, sendFilesToAnalyze } from "../services/SpreadsheetService"
import { refreshToken, useSupabase } from "./useSupabase"

export const useReconciliatioDatesResume = () => {
  const supabase = useSupabase()
  const [dates, setDates] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      setDates(await getReconciliationDatesResume(supabase))
      setIsLoading(false)
    }

    fetch()
  }, [supabase])

  return { dates, isLoading }
}

export const useOrganizeSpreadsheets = ({ dates }) => {
  const supabase = useSupabase()

  const [isLoading, setIsLoading] = useState(false)

  const { portfolio, extract, valuesToIdentify, date, setFidc, setDate, clear } = useContext(PortfolioReconciliationContext)
  const { toast } = useContext(GlobalContext)
  const [portfolioTable, setPortfolioTable] = useState(null)
  const [previousReconciliation, setPreviousReconciliation] = useState(null)
  const [extractTable, setExtractTable] = useState(null)

  const DEFAULT_STATUS = "Conectando"

  const [status, setStatus] = useState(DEFAULT_STATUS)

  const reloadPage = () => window.location.reload()

  const insertInDatabase = async () => {
    setIsLoading(true)

    try {
      await insertPortfolioReconciliation(
        supabase,
        portfolioTable.map((row) => ({
          fund: row.fund,
          data: row.data,
          tipo: row.tipo,
          description: row.description,
          quant: row.quant,
          PU: row.PU,
          value: row.value,
          id_fidc: row.id_fidc,
        })),
        extractTable.map((row) => ({
          fund: row.fund,
          data: row.data,
          account: row.account,
          description: row.description,
          balance: row.balance,
          debit: row.debit,
          credit: row.credit,
        })),
      )

      const shortName = await getFidcShortName(supabase, portfolioTable[0].id_fidc)
      const token = await refreshToken(supabase)

      await saveFilesInDataLake(portfolio, extract, valuesToIdentify, shortName, date, token)
    } finally {
      setIsLoading(false)
      reloadPage()
    }
  }

  const submit = async () => {
    if (!portfolio || !extract || !dates) return

    setIsLoading(true)

    const token = await refreshToken(supabase)
    const roomId = crypto.randomUUID()
    const channel = supabase.channel(roomId)

    await sendFilesToAnalyze(portfolio, extract, valuesToIdentify, dates, channel, roomId, token)

    channel.on("broadcast", { event: "reconciliation_response" }, ({ payload }) => {
      const idFidcUploaded = payload.result[0].id_fidc
      const reconciliation = dates.find((date) => date.id_fidc === idFidcUploaded)

      if (payload.date !== reconciliation.next_reconciliation) {
        toast.error({ message: `Você precisa conciliar ${reconciliation.next_reconciliation}` })
        clear()
      } else {
        setPortfolioTable(payload.result)
        setExtractTable(payload.extract)
        setFidc(payload.fidc)
        setDate(payload.date)
        setPreviousReconciliation(payload.previous)
      }

      supabase.removeChannel(channel)
      setStatus(DEFAULT_STATUS)
      setIsLoading(false)
    })

    channel.on("broadcast", { event: "reconciliation_status" }, ({ payload }) => setStatus(payload.status))

    channel.on("broadcast", { event: "reconciliation_error" }, ({ payload }) => {
      toast.error({ message: payload.error })
      clear()
      setStatus(DEFAULT_STATUS)
      setIsLoading(false)
    })

    channel.subscribe()
  }

  return { portfolioTable, extractTable, previousReconciliation, isLoading, status, insertInDatabase, reloadPage, submit }
}
