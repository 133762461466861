import { CheckOutlined, OrderedListOutlined, SearchOutlined, WalletOutlined } from "@ant-design/icons"
import { Button, Card, Flex, Upload } from "antd"
import { useContext } from "react"
import { PortfolioReconciliationContext } from "../context/PortfolioReconciliationContext"
import { colors } from "../style/colors"
import { spaces } from "../style/spaces"

export const PortfolioUpload = ({ onSubmit }) => {
  const { portfolio, setPortfolio, extract, setExtract, valuesToIdentify, setValuesToIdentify } = useContext(PortfolioReconciliationContext)

  const BUTTON_WIDTH = 200

  return (
    <>
      <Card title="Novo batimento" style={{ width: 400, alignItems: "center", marginTop: spaces.space4 }}>
        <Flex vertical style={{ alignItems: "center", gap: spaces.space1 }}>
          <p align="center">
            Para realizar novo batimento, faça o upload da Carteira, Extrato e Valores a identificar (se houver). A data e o FIDC serão selecionados
            automaticamente.
          </p>
          <Upload
            beforeUpload={(file) => {
              setPortfolio(file)
              return false
            }}
            onRemove={() => setPortfolio(null)}
            accept=".xls,.xlsx"
          >
            {!portfolio && (
              <Button type="primary" style={{ height: 50, width: BUTTON_WIDTH }}>
                <WalletOutlined style={{ fontSize: 24 }} />
                Carteira
              </Button>
            )}
          </Upload>
          <Upload
            beforeUpload={(file) => {
              setExtract(file)
              return false
            }}
            onRemove={() => setExtract(null)}
            accept=".xls,.xlsx"
          >
            {!extract && (
              <Button type="primary" style={{ height: 50, width: BUTTON_WIDTH }}>
                <OrderedListOutlined style={{ fontSize: 24 }} />
                Extrato
              </Button>
            )}
          </Upload>
          <Upload
            beforeUpload={(file) => {
              setValuesToIdentify(file)
              return false
            }}
            onRemove={() => setValuesToIdentify(null)}
            accept=".xls,.xlsx"
          >
            {!valuesToIdentify && (
              <Button type="primary" style={{ height: 50, width: BUTTON_WIDTH }}>
                <SearchOutlined style={{ fontSize: 24 }} />
                Valores a identificar
              </Button>
            )}
          </Upload>
          {portfolio && extract && (
            <Button
              type="primary"
              style={{ height: 50, width: BUTTON_WIDTH, backgroundColor: colors.green, marginTop: spaces.space2 }}
              onClick={onSubmit}
            >
              <CheckOutlined />
              Enviar
            </Button>
          )}
        </Flex>
      </Card>
    </>
  )
}
