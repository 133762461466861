import axios from "axios"

function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const sendFilesToAnalyze = async (portfolio, extract, valuesToIdentify, dates, channel, roomId, token) => {
  const data = { portfolio: await convertFileToBase64(portfolio), extract: await convertFileToBase64(extract), dates }

  if (valuesToIdentify) data.values_to_identify = await convertFileToBase64(valuesToIdentify)

  const start = Date.now()

  await await axios.post(
    `${process.env.REACT_APP_RECONCILIATION_SERVICE}/portfolio-reconciliation`,
    { room_id: roomId },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )

  const end = Date.now()

  await new Promise((resolve) => setTimeout(resolve, end - start > 1000 ? 8000 : 3000))

  await channel.send({ type: "broadcast", event: "reconciliation_request", payload: data })
}

export const saveFilesInDataLake = async (portfolio, extract, valuesToIdentify, fidcShortName, date, token) => {
  const data = { portfolio: await convertFileToBase64(portfolio), extract: await convertFileToBase64(extract), date, fidc_short_name: fidcShortName }

  if (valuesToIdentify) data.values_to_identify = await convertFileToBase64(valuesToIdentify)

  await axios.post(`${process.env.REACT_APP_RECONCILIATION_SERVICE}/save-raw-file`, data, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
