import { createContext, useState } from "react"

export const PortfolioReconciliationContext = createContext({
  date: null,
  portfolio: null,
  extract: null,
  valuesToIdentify: null,
  fidc: null,
  setDate: () => {},
  setPortfolio: () => {},
  setExtract: () => {},
  setValuesToIdentify: () => {},
  setFidc: () => {},
  clear: () => {},
})

export const PortfolioReconciliationProvider = ({ children }) => {
  const [portfolio, setPortfolio] = useState(null)
  const [extract, setExtract] = useState(null)
  const [valuesToIdentify, setValuesToIdentify] = useState(null)
  const [date, setDate] = useState(null)
  const [fidc, setFidc] = useState(null)

  const clear = () => {
    setPortfolio(null)
    setExtract(null)
    setValuesToIdentify(null)
    setDate(null)
    setFidc(null)
  }

  return (
    <PortfolioReconciliationContext.Provider
      value={{
        date,
        portfolio,
        extract,
        valuesToIdentify,
        fidc,
        setPortfolio,
        setExtract,
        setValuesToIdentify,
        setDate,
        setFidc,
        clear,
      }}
    >
      {children}
    </PortfolioReconciliationContext.Provider>
  )
}
