import { CheckOutlined, WarningOutlined } from "@ant-design/icons"
import { Button, Card, Collapse, Flex, Space, Statistic, Table } from "antd"
import { useContext } from "react"
import { Loader } from "../components/Loader"
import { PortfolioUpload } from "../components/PortfolioUpload"
import { PortfolioReconciliationContext } from "../context/PortfolioReconciliationContext"
import { useOrganizeSpreadsheets, useReconciliatioDatesResume } from "../hooks/usePortfolioReconciliation"
import { spaces } from "../style/spaces"
import { formatToReal } from "../utils/utils"

export const PortfolioReconciliation = () => {
  const { valuesToIdentify } = useContext(PortfolioReconciliationContext)
  const { dates, isLoading: isLoadingDates } = useReconciliatioDatesResume()
  const {
    portfolioTable,
    extractTable,
    previousReconciliation,
    isLoading: isLoadingReconciliation,
    status,
    insertInDatabase,
    reloadPage,
    submit,
  } = useOrganizeSpreadsheets({ dates })

  console.log("valuesToIdentify", valuesToIdentify)

  if (isLoadingDates || isLoadingReconciliation)
    return (
      <Flex vertical style={{ width: "max", height: "30vh", justifyContent: "center", gap: spaces.space1 }}>
        <Loader />
        {isLoadingDates && <h3 style={{ textAlign: "center" }}>Consultando últimas conciliações...</h3>}
        {isLoadingReconciliation && <h3 style={{ textAlign: "center" }}>{status}...</h3>}
      </Flex>
    )

  const columnsPortfolioReconciliation = [
    {
      title: "Fundo",
      dataIndex: "fund",
      key: "fund",
    },
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
      width: "100px",
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Quantidade",
      dataIndex: "quant",
      key: "quant",
    },
    {
      title: "PU",
      dataIndex: "PU",
      key: "PU",
      render: formatToReal,
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      render: formatToReal,
    },
  ]

  const comparationRender = {
    render: (value) => {
      if (value > 0) return <span style={{ color: "green" }}>{formatToReal(value)}</span>
      if (value < 0) return <span style={{ color: "red" }}>{formatToReal(value)}</span>
      return "N/A"
    },
  }

  const columnsToCompare = [
    ...columnsPortfolioReconciliation,
    {
      title: "Diferença",
      dataIndex: "difference",
      key: "difference",
      ...comparationRender,
    },
    {
      title: "Esperado",
      dataIndex: "expected",
      key: "expected",
      ...comparationRender,
    },
  ]

  const extractColumns = [
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Conta",
      dataIndex: "account",
      key: "account",
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Crédito",
      dataIndex: "credit",
      key: "credit",
      render: formatToReal,
    },
    {
      title: "Débito",
      dataIndex: "debit",
      key: "debit",
      render: formatToReal,
    },
    {
      title: "Saldo",
      dataIndex: "balance",
      key: "balance",
      render: formatToReal,
    },
  ]

  const collapseItems = [
    {
      key: "previousReconciliation",
      label: <b>{`Carteira anterior - ${previousReconciliation?.[0].data}`}</b>,
      children: <Table dataSource={previousReconciliation} pagination={false} columns={columnsPortfolioReconciliation} size="small" />,
    },
    {
      key: "portfolioToReconciliate",
      label: <b>Carteira</b>,
      children: <Table dataSource={portfolioTable} pagination={false} columns={columnsToCompare} size="small" />,
    },
    {
      key: "extractReconciliation",
      label: <b>Extrato</b>,
      children: <Table dataSource={extractTable} pagination={false} columns={extractColumns} size="small" />,
    },
    // {
    //   key: "valuesToIdentify",
    //   label: <b>Valores a identificar</b>,
    //   children: <h1>Valores a identificar</h1>,
    // },
  ]

  return (
    <>
      <Flex vertical style={{ padding: spaces.space2, height: "100%" }}>
        <Flex gap={spaces.space1}>
          {dates.map((date) => (
            <Card title={date.fund} size="small" styles={{ body: { display: "flex", flexDirection: "column", alignItems: "flex-end" } }}>
              <Statistic
                title={<span style={{ fontSize: "16px" }}>Último batimento:</span>}
                value={date.last_reconciliation}
                valueStyle={{ color: "green", display: "inline-block", marginLeft: "10px", fontSize: "16px", fontWeight: 500 }}
                prefix={<CheckOutlined style={{ color: "green", fontSize: "16px" }} />}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              />
              <Statistic
                title={<span style={{ fontSize: "16px" }}>Pendente:</span>}
                value={date.next_reconciliation}
                valueStyle={{ color: "red", display: "inline-block", marginLeft: "10px", fontSize: "16px", fontWeight: 500 }}
                prefix={<WarningOutlined style={{ color: "red", fontSize: "16px" }} />}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              />
            </Card>
          ))}
        </Flex>
        <Space style={{ flex: 1, width: "100%", marginTop: spaces.space2, justifyContent: "center" }}>
          {!portfolioTable || !extractTable ? (
            <PortfolioUpload onSubmit={submit} />
          ) : (
            <Flex vertical>
              <Collapse items={collapseItems} style={{ width: "100%" }} defaultActiveKey={["portfolioToReconciliate"]} />
              <Space style={{ gap: spaces.space1, marginTop: spaces.space2 }}>
                <Button type="primary" onClick={insertInDatabase}>
                  Subir batimento para o banco de dados
                </Button>
                <Button onClick={reloadPage}>Limpar</Button>
              </Space>
            </Flex>
          )}
        </Space>
      </Flex>
    </>
  )
}
