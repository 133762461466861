import { TABLES } from "../utils/supabase"

export const getFidcs = async (supabase) => {
  const { data, error } = await supabase.from(TABLES.FIDC).select("id, short_name")
  if (error) throw error
  return data
}

export const getFidcShortName = async (supabase, id) => {
  const { data, error } = await supabase.from(TABLES.FIDC).select("short_name").eq("id", id)
  if (error) throw error
  return data[0].short_name
}

export const getTranches = async (supabase, fidc) => {
  const { data, error } = await supabase.from(TABLES.TRANCHE).select("id, name").eq("id_fidc", fidc)
  if (error) throw error
  return data
}
