import { useEffect, useState } from "react"
import { getFidcs, getTranches } from "../services/FidcService"
import { useSupabase } from "./useSupabase"

export const useAmortization = ({ idFidc }) => {
  const supabase = useSupabase()
  const [isLoading, setIsLoading] = useState(false)
  const [fidcs, setFidcs] = useState([])
  const [tranches, setTranches] = useState([])

  useEffect(() => {
    setIsLoading(true)
    getFidcs(supabase).then((fidcs) => {
      setFidcs(fidcs)
      setIsLoading(false)
    })
  }, [supabase])

  useEffect(() => {
    if (!idFidc) return
    setIsLoading(true)
    getTranches(supabase, idFidc).then((tranches) => {
      setTranches(tranches)
      setIsLoading(false)
    })
  }, [idFidc, supabase])

  return { isLoading, fidcs, tranches }
}
