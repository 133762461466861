import { createClient } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { Home } from "./Home";
import { Loader } from "../components/Loader";
import LoginWithMicrosoft from "./Login/LoginWithMicrosoft";
import useNotification from "antd/es/notification/useNotification";

export const App = () => {
  const [supabase, setSupabase] = useState(null);
  const [session, setSession] = useState(null);
  const [api, contextHolder] = useNotification()

  useEffect(
    () =>
      setSupabase(
        createClient(
          process.env.REACT_APP_SUPABASE_URL,
          process.env.REACT_APP_SUPABASE_ANON_KEY
        )
      ),
    []
  );

  useEffect(() => {
    if (supabase)
      supabase.auth
        .getSession()
        .then(({ data: { session } }) => setSession(session));
  }, [supabase]);

  return (
    <GlobalContext.Provider value={{ supabase, session, toast: api }}>
      {contextHolder}
      {!supabase && <Loader />}
      {supabase && !session && <LoginWithMicrosoft />}
      {supabase && session && <Home />}
    </GlobalContext.Provider>
  );
};

export default App;
