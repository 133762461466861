import React from "react";
import ReactDOM from "react-dom/client";
import App from "./pages/App";
import "./style/global.css";
import { colors } from "./style/colors";
import { ConfigProvider } from "antd";

const designSystem = {
  colorPrimary: colors.primary,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider theme={{ token: designSystem }}>
    <App />
  </ConfigProvider>
);
