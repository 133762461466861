import { useContext } from "react";
import { Button, Flex } from "antd";
import { WindowsFilled } from "@ant-design/icons";
import { GlobalContext } from "../../context/GlobalContext";
import { spaces } from "../../style/spaces";
import { Logo } from "../../components/Logo";
import BackgroundImage from "../../assets/background-login.png";

export default function LoginWithMicrosoft() {
  const { supabase } = useContext(GlobalContext);

  const handleAzureLogin = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "azure",
      options: {
        scopes: "email",
        redirectTo: window.location.href,
      },
    });
    if (error) {
      console.error("Error logging in with Azure:", error.message);
    }
  };

  return (
    <Flex
      gap={spaces.space5}
      style={{
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: "url(" + BackgroundImage + ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      vertical
    >
      <Logo
        width={300}
        src={
          "https://iwluwlzcpkxhdjrcanka.supabase.co/storage/v1/object/public/assets/tera-logo-branco.png"
        }
      />
      <Button
        onClick={handleAzureLogin}
        type="primary"
        icon={<WindowsFilled />}
        style={{ width: 300, height: 50 }}
      >
        Login com Microsoft
      </Button>
    </Flex>
  );
}

