import { Layout } from "antd"
import { useState } from "react"
import { HeaderMenu } from "../components/HeaderMenu"
import { Logo } from "../components/Logo"
import { PortfolioReconciliationProvider } from "../context/PortfolioReconciliationContext"
import { colors } from "../style/colors"
import { spaces } from "../style/spaces"
import { Amortizations } from "../templates/Amortizations"
import { PortfolioReconciliation } from "../templates/PortfolioReconciliation"

export const Home = () => {
  const [menuKey, setMenuKey] = useState("1")
  const HEADER_HEIGHT = 64

  const menuItems = [
    {
      key: "1",
      label: "Batimento de Carteiras",
      children: (
        <PortfolioReconciliationProvider>
          <PortfolioReconciliation />
        </PortfolioReconciliationProvider>
      ),
    },
    {
      key: "2",
      label: "Amortizações",
      children: <Amortizations />,
    },
  ]

  return (
    <Layout style={{ height: "auto", minHeight: "100vh" }}>
      <Layout.Header
        style={{
          backgroundColor: colors.menuBackgroundColor,
          display: "flex",
          alignItems: "center",
          paddingRight: spaces.space5,
          paddingLeft: spaces.space5,
          position: "sticky",
          top: 0,
          zIndex: 1,
          height: HEADER_HEIGHT,
          borderBottom: `1px solid ${colors.menuBorderColor}`,
        }}
      >
        <Logo width={105} height={30} marginRight={spaces.space5} />
        <HeaderMenu
          setMenuKey={setMenuKey}
          menuItems={menuItems.map((item) => ({
            key: item.key,
            label: item.label,
          }))}
        />
      </Layout.Header>
      <Layout.Content>{menuItems.find((item) => item.key === menuKey)?.children}</Layout.Content>
    </Layout>
  )
}
